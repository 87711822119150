import React from 'react';
import { graphql } from 'gatsby'

import GamesLayout from '../../components/GamesLayout';
import useMatchedQuery from '../../hooks/useMatchedQuery';

import ContentfulComponentSeo from '../../ContentfulComponents/ContentfulComponentSeo';

import ContentfulComponentHero from '../../ContentfulComponents/ContentfulComponentHero';

import ContentfulComponentBlock from '../../ContentfulComponents/ContentfulComponentBlock';


export const query = graphql`
query {
 

    contentfulComponentSeo0: contentfulComponentSeo (node_locale: {eq: "es-AR"}, contentful_id: {eq: "3VeVrZS9ZkgIkPAD6MbmQf"}) {
      
  title
  type
  description {
    description
  }
  image {
    id
    title
    gatsbyImageData(
      layout: FULL_WIDTH,
      placeholder: BLURRED,
    )
  }

      internal {
        type
      }    
    }
  





    contentfulComponentHero1: contentfulComponentHero (node_locale: {eq: "es-AR"}, contentful_id: {eq: "5dMbhuZ4WLRE4BlyiAyJcV"}) {
      
id
title
contentful_id
node_locale
codeHero
gridModel
gridPosition
portraitGridModel
portraitGridPosition
textPosition
textColor
image {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
portraitImage {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
overlayText {
  raw
}

      internal {
        type
      }    
    }
  





    contentfulComponentBlock2: contentfulComponentBlock (node_locale: {eq: "es-AR"}, contentful_id: {eq: "5HDrEnXtZqjy21y5HXColQ"}) {
      
id
name
contentful_id
node_locale
title
direction
cta {
  link
  label
  color
}
reveal {
  type
  props
}
image {
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
    width: 500,
  )  
}
content {
  raw
}

      internal {
        type
      }    
    }
  





    contentfulComponentBlock3: contentfulComponentBlock (node_locale: {eq: "es-AR"}, contentful_id: {eq: "6EBANEUWsvX9uWQOPz6V8"}) {
      
id
name
contentful_id
node_locale
title
direction
cta {
  link
  label
  color
}
reveal {
  type
  props
}
image {
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
    width: 500,
  )  
}
content {
  raw
}

      internal {
        type
      }    
    }
  



 
 
 site {
  siteMetadata {
    siteUrl
  }
}}

`

const Page = (props) => {
  const seo = props.pageContext.seo;
  const matchedQuery = useMatchedQuery()
  const { contentIds, ...context } = props.pageContext
  const { data } = props;
  return (
    <GamesLayout {...context} matchedQuery={matchedQuery} useHeader={true} useContainer={false} withSearch={false} >
      
      <ContentfulComponentSeo {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={false} componentId="contentfulComponentSeo0" pathName="/contenido/venir-a-jugar" />
      
      <ContentfulComponentHero {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={false} componentId="contentfulComponentHero1" pathName="/contenido/venir-a-jugar" />
      
      <ContentfulComponentBlock {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={false} componentId="contentfulComponentBlock2" pathName="/contenido/venir-a-jugar" />
      
      <ContentfulComponentBlock {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={false} componentId="contentfulComponentBlock3" pathName="/contenido/venir-a-jugar" />
      
    </GamesLayout>
  )
}

export default Page;
